<template>
  <v-dialog eager fullscreen persistent hide-overlay v-model="show" width="700px">
    <v-card >
      <v-card-title class="headline primary" >
      <v-btn @click="Close()" icon color="white">
        <v-icon>mdi-close</v-icon>
      </v-btn>
        <v-spacer></v-spacer>
      <v-btn @click="Guardar()">
        <v-icon>mdi-playlist-plus</v-icon>Guardar
      </v-btn>
      <v-spacer></v-spacer>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-row dense class="mt-4" >
          <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-checkbox 
              dense 
              v-model="registroActual.persona_moral" 
              label="Persona moral"  
              color="success" 
              hide-details>
            </v-checkbox>  
          </v-col>
          <v-col cols="12" xs="12" 
            :sm="registroActual.persona_moral?9:3" 
            :md="registroActual.persona_moral?9:3" 
            :lg="registroActual.persona_moral?9:3" 
            :xl="registroActual.persona_moral?9:3">
            
            <v-text-field 
              required 
              :label="registroActual.persona_moral?`Razon social *`:`Nombre(s) *`" 
              :rules="rules.Valor"
              dense 
              persistent-hint 
              v-model="registroActual.nombre">
            </v-text-field>
          
          </v-col>
          <v-col v-if="!registroActual.persona_moral" cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field 
              required 
              label="Primer apellido"  
              dense 
              persistent-hint 
              v-model="registroActual.apellido_paterno"
            ></v-text-field>
            </v-col>
          <v-col v-if="!registroActual.persona_moral" cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
            <v-text-field  
              label="Segundo Apellido" 
              dense 
              persistent-hint 
              v-model="registroActual.apellido_materno"
              ></v-text-field>
          </v-col> 
        </v-row>       
         
          <v-tabs v-model="tab" background-color="primary" dark 
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tabs-slider color="red"></v-tabs-slider>
          
            <v-tab key="1">DIRECCION</v-tab>
            <v-tab key="2">VENTAS</v-tab>
            <v-tab key="3">PERSONALES</v-tab>
            <v-tab key="4">CONFIGURACION</v-tab>  
            <v-tab key="5">BANCARIOS</v-tab> 
            <v-tab key="6">CONTABILIDAD</v-tab>
            <v-tab key="7">CENTROS DE COSTO</v-tab>
            <v-tab key="8" v-if="!isNew">ENTREGA</v-tab>
            <v-tab key="9" v-if="!isNew">FACTURACION</v-tab>
          </v-tabs>

          <v-tabs-items v-model="tab">
              
              <v-tab-item key=1> <!----Direccion-->
                <h-direccion 
                  :id="direccion_id" 
                  :isNew="isNew" 
                  @update="onUpdateDir" >
                </h-direccion>
              </v-tab-item>
              <!-- #region Body -->
              <!-- #endregion -->
              <v-tab-item key=2> <!----Ventas-->
                <v-data-table
                  :headers="headerVentas"
                  item-key="id"
                  :search="search"
                  :loading="loading"
                  class="elevation-1 pt-n4"
                  multi-sort
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  :items="items_ventas"
                  :page="currentPage"
                  :items-per-page="perPage"
                  :options.sync="options"
                  items-per-page-options="[20,50,100,-1]"
                  :server-items-length="totalRegistros" 
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                  }"
                >
                <template v-slot:top>
                  <v-toolbar flat color="primary">
                  <v-card-title class="white--text">Ventas</v-card-title>
                  <v-btn @click="AddVenta()">
                    <v-icon>mdi-playlist-plus</v-icon>Agregar Venta
                  </v-btn>
                    <v-spacer></v-spacer>
                  <v-text-field
                    color="white"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    solo
                  ></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:item.nombre="{ item }">
                  <span class="caption">{{item.nombre}}</span>
                </template>
                <template  v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="Editar(item.id)">mdi-pencil</v-icon>
                  <v-icon @click="Eliminar(item.id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
              </v-tab-item>

              <v-tab-item key=3>
                <v-row dense class="mt-4" v-for="(item,index) in registroDatosPersonales" :key="index">
                  <v-col cols="12" xs="12" sm="2" md="2" lg="1" xl="1">
                    <v-combobox 
                    dense 
                    label="Tipo" 
                    :items="tipoDato" 
                    v-model="item.tag"    
                    persistent-hint
                  /></v-col>
                  <v-col cols="12" xs="12" sm="2" md="3" lg="1" xl="1">
                    <v-text-field  
                      required 
                      label="Etiqueta" 
                      dense 
                      persistent-hint 
                      v-model="item.nombre">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="2">
                    <v-text-field 
                      v-if="item.tag!=`TelÃ©fono`"
                      required 
                      label="Valor" 
                      dense 
                      persistent-hint 
                      v-model="item.valor">
                    </v-text-field>
                    <v-text-field 
                      v-else
                      required 
                      label="Valor numÃ©rico" 
                      dense 
                      persistent-hint 
                      v-model="item.valor">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="2" xl="1">
                    <v-checkbox 
                      dense 
                      v-model="item.predeterminado" 
                      label="Default"  
                      color="success" 
                      hide-details>
                    </v-checkbox>  
                  </v-col>
               
                </v-row>
                <v-row dense :class="registroDatosPersonales.length==0?`mt-4`:``"  >
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-btn   
                      color="secondary"
                      depressed
                      @click="agregarDato()"
                      ><v-icon>mdi-plus</v-icon>AGREGAR</v-btn>
                  </v-col>
                </v-row>
              </v-tab-item>
              
              <v-tab-item key=4> 
                <v-row dense class="mt-4">
                  <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="1">
                    <v-checkbox dense v-model="registroActual.cliente" label="Cliente" color="success" hide-details></v-checkbox>
                  </v-col>
                  <v-col v-if="registroActual.cliente" cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-autocomplete 
                      dense 
                      label="Lista de precio" 
                      :items="lista_precios" 
                      v-model="registroActual.lista_precio_cliente_id"  
                      item-text="nombre" 
                      item-value="id" 
                      persistent-hint
                    />
                  </v-col>
                  <v-col v-if="registroActual.cliente" cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-autocomplete 
                      dense 
                      label="Perfil de impuesto" 
                      :items="impuestos_perfiles" 
                      v-model="registroActual.impuesto_perfil"  
                      item-text="nombre"
                      item-value="id"
                      persistent-hint
                    />
                  </v-col>
                  
                </v-row> 
                <v-row dense>
                  <v-col cols="12" xs="12" sm="3" md="2" lg="2" xl="1">
                    <v-checkbox dense v-model="registroActual.proveedor" label="Proveedor"  color="success" hide-details></v-checkbox>
                  </v-col>
                  <v-col v-if="registroActual.proveedor" cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Lista de precio proveedor" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.lista_precio_proveedor_id">
                    </v-text-field>
                  </v-col>
                 
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-checkbox 
                      dense 
                      v-model="registroActual.deudor" 
                      label="Deudor"  
                      color="success" 
                      hide-details>
                    </v-checkbox>
                  </v-col>
                 
                </v-row>
                <v-row dense>
                  <v-col  cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-checkbox 
                      dense 
                      v-model="registroActual.acreedor" 
                      label="Acreedor"  
                      color="success" 
                      hide-details>
                    </v-checkbox>
                  </v-col>
                  
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-checkbox 
                      dense 
                      v-model="registroActual.empleado" 
                      label="Empleado"  
                      color="success" 
                      hide-details>
                    </v-checkbox>
                  </v-col>
                 
                </v-row>
                <v-row dense v-if="registroActual.empleado">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-checkbox 
                      dense 
                      v-model="registroActual.vendedor" 
                      label="Vendedor"  
                      color="success" 
                      hide-details>
                    </v-checkbox>
                  </v-col>
                </v-row>
                 <v-row dense v-if="registroActual.empleado">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-checkbox 
                      dense 
                      v-model="registroActual.cajero" 
                      label="Cajero"  
                      color="success" 
                      hide-details>
                    </v-checkbox>
                  </v-col>
                </v-row>
                 <v-row dense v-if="registroActual.empleado">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-checkbox 
                      dense 
                      v-model="registroActual.tecnico" 
                      label="TÃ©cnico"  
                      color="success" 
                      hide-details>
                    </v-checkbox>
                  </v-col>
                 
                </v-row>
 
              </v-tab-item> 
  <!----bancarios-->
              <v-tab-item key=5>

                <v-row dense class="mt-4">
                  <v-col cols="12">
                  <div>
                    <v-data-table
                      class="elevation-3"
                      :headers="headerBancarios"
                      :items="registrosBancos"
                      item-key="id"
                      :items-per-page="20"  
                      hide-default-footer
                    >
                      <template  v-slot:[`item.banco`]="{ item }">
                        <span>{{ getBancoNombre(item.banco)}}</span>
                      </template>
                      <template  v-slot:[`item.actions`]="{ item }">
                        <v-icon color="red" @click="activarBanco(item)">mdi-delete</v-icon>
                        <v-icon color="green" @click="editarBanco(item)">mdi-pencil</v-icon>
                      </template>
                      <template v-slot:[`item.estatus`]="{ item }">
                        <span>{{ item.estatus?'ACTIVO':'BAJA' }}</span>
                      </template>
                    </v-data-table>
                  </div>
 
                  </v-col>
                  <v-col cols="2">
                    <v-btn
                      color="primary"
                      elevation="2"
                      large
                      tile
                      @click="agregarBanco()"
                      ><v-icon>mdi-plus</v-icon>AGREGAR
                    </v-btn>
                  </v-col>
                </v-row>  

              </v-tab-item >

              <v-tab-item key=6>
               
                <v-row dense class="mt-2" v-if="registroActual.cliente">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable cliente" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_cliente">
                    </v-text-field>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable cliente complementaria" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_cliente_comp">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable cliente anticipo" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_cliente_anticipo">
                    </v-text-field>
                  </v-col>
                </v-row>  

                <v-row dense class="mt-2"  v-if="registroActual.proveedor">
                   <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable proveedor" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_proveedor">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable proveedor complementaria" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_proveedor_comp">
                    </v-text-field>
                  </v-col>
                  <v-col v-if="registroActual.proveedor" cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable proveedor anticipo" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_proveedor_anticipo">
                    </v-text-field>
                  </v-col>
                </v-row>   
                <v-row dense class="mt-2" v-if="registroActual.deudor">
                  <v-col  cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable deudor" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_deudor">
                    </v-text-field>
                  </v-col>
                  <v-col   cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable deudor complementaria" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_deudor_comp">
                    </v-text-field>
                  </v-col>
                </v-row>  
                
                <v-row dense class="mt-2" v-if="registroActual.acreedor">
                 <v-col  cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable acreedor" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_acreedor">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable acreedor complementaria" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_acreedor_comp">
                    </v-text-field>
                  </v-col>
                </v-row>  
  
                <v-row dense class="mt-2" v-if="registroActual.empleado">
                  <v-col cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable empleado" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_empleado">
                    </v-text-field>
                  </v-col>
                   <v-col  cols="12" xs="12" sm="4" md="4" lg="3" xl="3">
                    <v-text-field 
                      required 
                      label="Cuenta contable empleado complementaria" 
                      dense 
                      persistent-hint 
                      v-model="registroActual.cuenta_contable_empleado_comp">
                    </v-text-field>
                  </v-col>
                </v-row>  

              </v-tab-item>

  <!-- centros de costo-->
              <v-tab-item key=7 v-if="!isNew">

                <v-row dense class="mt-4">
                  <v-col cols="12">
 
                  </v-col>
                </v-row>  

              </v-tab-item>
 
              <v-tab-item key=8 v-if="!isNew">

                <v-row dense class="mt-4">
                  <v-col cols="12">
 
                    <v-data-table
                      class="elevation-3"
                      :headers="headerDirecciones"
                      :items="registroActual.direcciones_entrega"
                      item-key="id"
                      :items-per-page="20"  
                      hide-default-footer
                    > 

                    <template  v-slot:[`item.cruzamiento_1`]="{ item }">
                      <span>{{item.cruzamiento_1 }}</span>
                      <span v-if="item.cruzamiento_2!=``"> y </span>
                      <span>{{item.cruzamiento_2 }}</span>
                    </template>

                    <template  v-slot:[`item.localidad`]="{ item }">
                      <span>{{item.localidad }}</span>
                      <span v-if="item.municipio!=``">,</span>
                      <span>{{item.municipio }}</span>
                      <span v-if="item.entidad!=``">,</span>
                      <span>{{item.entidad }}</span>
                       <span v-if="item.pais!=``">,</span>
                      <span>{{item.pais }}</span>
                    </template>

                    <template  v-slot:[`item.estatus`]="{ item }">
                      <span v-if="item.estatus==false">BAJA</span>
                      <span v-else>ACTIVO</span>
                    </template>
                      
                    <template  v-slot:[`item.actions`]="{ item }">
                      <v-icon @click="editarDireccionEntrega(item)">
                        mdi-launch
                      </v-icon>
                    </template>
                    </v-data-table>
 
                  </v-col>
                </v-row>  

                <v-row dense class="mt-4">
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-btn   
                      color="secondary"
                      depressed
                      @click="nuevaDireccionEntrega()"
                      ><v-icon>mdi-plus</v-icon>AGREGAR</v-btn>
                  </v-col>
                </v-row>

              </v-tab-item >

              <v-tab-item key=9 v-if="!isNew">

                <v-row dense class="mt-4">
                  <v-col cols="12">
 
                    <v-data-table
                      class="elevation-3"
                      :headers="headerRazonesSociales"
                      :items="registroActual.razones_sociales"
                      item-key="id"
                      :items-per-page="20"  
                      hide-default-footer
                    > 
 
                    <template  v-slot:[`item.estatus`]="{ item }">
                      <span v-if="item.estatus==false">BAJA</span>
                      <span v-else>ACTIVO</span>
                    </template>
                      
                    <template  v-slot:[`item.actions`]="{ item }">
                      
                      <v-btn icon><v-icon title="Desvincular" @click="desvincularRazonSocial(item.id)" color="red">mdi-link-off</v-icon></v-btn>
                      <v-btn icon><v-icon title="Editar" @click="editarRazonSocial(item)">mdi-launch</v-icon></v-btn>  
                    
                    </template>
                    </v-data-table>
 
                  </v-col>
                </v-row>  
 
                <v-row dense class="mt-4">
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                    <v-btn   
                      color="secondary"
                      depressed
                      @click="nuevaRazonSocial()"
                      ><v-icon>mdi-plus</v-icon>AGREGAR</v-btn>
                  </v-col>
                </v-row>
 
              </v-tab-item>
 
          </v-tabs-items>
 

      </v-card-text> 
    
    </v-card>
    <dialog-ventas
      :show="showDialogVenta"
      :isNew="isNewVenta"
      :export_contacto_id="id"
      @close="showDialogVenta=false"
      @new="CloseVenta"

    />
  
    <v-dialog eager persistent hide-overlay v-model="showDialogDireccion" width="700px" scrollable>
      <v-card >
        <v-card-title class="headline primary" >
          <v-toolbar  flat dark color="primary" >
            <v-btn icon dark @click="showDialogDireccion=false"  >
              <v-icon>mdi-close</v-icon>
            </v-btn>
            <v-toolbar-title v-if="isNewDireccion">Nueva direcciÃ³n</v-toolbar-title>
            <v-toolbar-title v-if="!isNewDireccion">Editar direcciÃ³n</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn dark icon title="Guardar" 
              @click="GuardarDireccion()"
            > <v-icon>mdi-content-save-outline</v-icon></v-btn>
            <v-spacer></v-spacer>
            <v-btn  v-if="!isNewDireccion&&registroActual.estatus==true" color="red" icon title="Eliminar"
              @click="EliminarDireccion()"
            ><v-icon>mdi-delete</v-icon></v-btn>
          </v-toolbar>
        </v-card-title> 
        <v-card-text>
           <h-direccion 
            :showName="true"
            :id="direccion_actual_id" 
            :isNew="isNewDireccion" 
            @update="onUpdateDirActual" >
          </h-direccion>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showDialogEmpresa" hide-overlay persistent transition="dialog-bottom-transition" max-width="700px" scrollable>
        <v-card>
          <v-card-title class="headline primary">
            <v-toolbar  flat dark color="primary" >
              <v-btn icon dark @click="showDialogEmpresa=false" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>RazÃ³n social</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn dark icon title="Guardar" 
              @click="guardarRazonSocial()"
              > <v-icon>mdi-content-save-outline</v-icon></v-btn>
              <v-spacer></v-spacer>
              <v-btn  v-if="!isNewRazonSocial&&registroActualRazonSocial.estatus==true" color="red" icon title="Baja"
                @click="eliminarRazonSocial()"
              ><v-icon>mdi-delete</v-icon></v-btn>

            </v-toolbar>
          </v-card-title>
          <v-card-text>
          
              <v-form ref="form">
                  
                <v-row dense class="mt-4">
                  <v-col cols="12" xs="12" sm="4" md="3" lg="4" xl="4">
                    <v-checkbox 
                      dense 
                      v-model="registroActualRazonSocial.persona_moral" 
                      label="Persona moral"  
                      color="success" 
                      hide-details>
                    </v-checkbox>  
                  </v-col>
                  <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="43">
                    <v-text-field 
                    dense clearable v-model="registroActualRazonSocial.rfc" label="RFC" 
                    @change="buscarRFC()"
                    />
                  </v-col>
                    <v-col cols="12" xs="12" sm="4" md="4" lg="4" xl="4">
                    <v-menu ref="menu1" v-model="dpFecha" :close-on-content-click="false" transition="scale-transition">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                        dense 
                        clearable  
                        v-model="fecha_inicio"  
                        label="Fecha de inicio" 
                        :rules="rules.fecha"
                        persistent-hint 
                        prepend-icon="event" 
                        v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                      <v-date-picker v-model="registroActualRazonSocial.fecha_inicio" no-title @input="dpFecha = false"></v-date-picker>
                    </v-menu>
                  </v-col>
                  
                </v-row>
                <v-row dense>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                    <v-autocomplete  
                      dense  
                      v-model="registroActualRazonSocial.uso_cfdi" 
                      label="Uso de CFDI" 
                      :items="usos_cfdi" 
                      item-text="descripcion" 
                      item-value="id"
                    />
                  </v-col>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                    <v-autocomplete 
                      dense 
                      label="MÃ©todo de pago" 
                      :items="metodos_pago" 
                      v-model="registroActualRazonSocial.metodo_pago"  
                      item-text="descripcion" 
                      item-value="id" 
                      persistent-hint
                    />
                  </v-col>
                  <v-col cols="4" sm="4" md="4" lg="4" xl="4">
                    <v-autocomplete 
                      dense 
                      label="Forma de pago" 
                      :items="formas_pago" 
                      v-model="registroActualRazonSocial.forma_pago"  
                      item-text="nombre" 
                      item-value="codigo_sat" 
                      persistent-hint
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualRazonSocial.razon_social" 
                    label="Razon social"  />
                  </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field  
                    clearable 
                    dense 
                    :rules="rules.nombre"
                    v-model="registroActualRazonSocial.regimen_capital" 
                    label="Regimen capital"
                    />
                  </v-col>
                </v-row> 
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-autocomplete 
                    dense 
                    label="Regimen fiscal" 
                    :items="regimenFiscal" 
                    :rules="rules.nombre"
                    v-model="registroActualRazonSocial.regimen_fiscal"  
                    item-text="descripcion" 
                    item-value="id" 
                    persistent-hint/>
                  </v-col>
                </v-row>
                <v-row dense>
                    <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field  
                    clearable 
                    dense 
                    :rules="rules.nombre"
                    v-model="registroActualRazonSocial.representante_legal" 
                    label="Representante legal"
                    />
                  </v-col>
                </v-row> 
               
                <v-row dense class="mt-4">  
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Vialidad"  dense persistent-hint v-model="registroActualDireccion.vialidad"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense >
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Numero Exterior"  dense persistent-hint v-model="registroActualDireccion.numero_exterior"></v-text-field>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Numero Interior"  dense persistent-hint v-model="registroActualDireccion.numero_interior"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense > 
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Cruzamiento 1"  dense persistent-hint v-model="registroActualDireccion.cruzamiento_1"></v-text-field>
                  </v-col> 
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Cruzamiento 2"  dense persistent-hint v-model="registroActualDireccion.cruzamiento_2"></v-text-field>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Codigo Postal" @change="buscarCP()" dense persistent-hint v-model="registroActualDireccion.codigo_postal"></v-text-field>
                  </v-col>
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-autocomplete 
                      v-if="!capturarColonia"
                      dense 
                      label="Colonia" 
                      :items="colonias" 
                      :rules="rules.nombre"
                      v-model="coloniaSeleccionada"  
                      item-text="nombre" 
                      item-value="id" 
                      return-object
                      append-outer-icon="mdi-pencil" 
                      @change="()=>{registroActualDireccion.colonia_id = coloniaSeleccionada.c_Colonia;registroActualDireccion.colonia = coloniaSeleccionada.nombre;}"
                      @click:append-outer="()=>{capturarColonia=true;coloniaSeleccionada.c_Colonia='';}"
                      persistent-hint/> 
                      <v-text-field 
                        v-if="capturarColonia" 
                        label="Colonia"  
                        dense 
                        persistent-hint 
                        append-outer-icon="mdi-form-select"  
                        @change="coloniaSeleccionada={}"
                        @click:append-outer="capturarColonia=false"
                        v-model="registroActualDireccion.colonia">
                      </v-text-field>
                  </v-col>
                </v-row>
                <v-row dense >
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field   
                    :label="`Localidad (${registroActualDireccion.localidad_id})`"  
                    dense 
                    persistent-hint 
                    v-model="registroActualDireccion.localidad"  
                    ></v-text-field>
                  </v-col>
                  
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Municipio"  dense persistent-hint v-model="registroActualDireccion.municipio" ></v-text-field>
                  </v-col>

                </v-row>
                <v-row dense>
                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Entidad"  dense persistent-hint v-model="registroActualDireccion.entidad"></v-text-field>
                  </v-col>

                  <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
                    <v-text-field  label="Pais"  dense persistent-hint v-model="registroActualDireccion.pais"></v-text-field>
                  </v-col>
                </v-row> 
             
             </v-form>
               
            </v-card-text>
        </v-card>
    </v-dialog>
    <v-dialog v-model="showDialogBanco" hide-overlay persistent transition="dialog-bottom-transition" max-width="700px" scrollable>
        <v-card>
          <v-card-title class="headline primary">
            <v-toolbar  flat dark color="primary" >
              <v-btn icon dark @click="showDialogBanco=false" >
                <v-icon>mdi-close</v-icon>
              </v-btn>
              <v-toolbar-title>Banco</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn dark icon title="Guardar" 
              @click="guardarBanco()"
              > <v-icon>mdi-content-save-outline</v-icon></v-btn>
              <v-spacer></v-spacer>
              <v-btn  v-if="!isNewRazonSocial&&registroActualRazonSocial.estatus==true" color="red" icon title="Baja"
                @click="eliminarBanco()"
              ><v-icon>mdi-delete</v-icon></v-btn>

            </v-toolbar>
          </v-card-title>
          <v-card-text>
          
              <v-form ref="form">
                  
                <v-row dense class="mt-4">
                  <v-col cols="6" xs="3" sm="6" md="6" lg="6" xl="6">
                    <v-autocomplete 
                    dense 
                    label="Banco" 
                    :items="bancosSAT" 
                    v-model="registroActualBanco.banco"  
                    item-text="descripcion" 
                    item-value="clave" 
                    persistent-hint
                  />  
                  </v-col>
                  <v-col cols="6" xs="3" sm="6" md="6" lg="6" xl="6">
                    <v-select
                      dense
                      :items="bancosSAT"
                      v-model="registroActualBanco.banco"  
                      label="Clave"
                      item-text="clave" 
                      item-value="clave"
                      readonly
                    ></v-select>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="6" sm="6" md="6" lg="6" xl="6">
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualBanco.cuenta" 
                    label="Cuenta Bancaria"  />
                  </v-col>
                  <v-col>
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualBanco.tarjeta" 
                    label="Tarjeta Bancaria"  />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualBanco.clabe" 
                    label="CLABE"  />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualBanco.convenio" 
                    label="Convenio"  />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualBanco.swift" 
                    label="SWIFT"  />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="12" md="12" lg="12" xl="12">
                    <v-text-field 
                    dense 
                    clearable 
                    persistent-hint
                    :rules="rules.nombre"
                    v-model="registroActualBanco.sucursal" 
                    label="Sucursal"  />
                  </v-col>
                </v-row> 
             </v-form>
            </v-card-text>
        </v-card>
    </v-dialog>
  </v-dialog>
</template>
<script>
import axios from 'axios'; 
import Vue from 'vue'
  import HDireccion from "@/components/h-direccion.vue";
  import DialogVentas from "@/components/dialogs/DialogVentas.vue";
export default {
    components: {
      HDireccion,
      DialogVentas
	  },
    props:[
      'id',
      'show',
      'isNew',
      'pantalla'
    ],
    data: () => ({
      baseURL: '/contactos',
      direccion_id: null,
      registroActual: {},
      rules: {}, 
      registroActualBanco: {},
      registroDatosPersonales: [],
      showDialogBanco: false,
      showDialogEmpresa: false,
      showDialogDireccion: false,
      registroActual:{
        datos_personales:{},
        datos_bancarios:{}
      },
      tipoDato :[
        "Teléfono",
        "Correo",
        "Apps",
        "Personalizado"
      ],
      headerDirecciones:[ 
        { text: 'Nombre', align:'start',value: 'nombre', width:200},
        { text: 'Vialidad', align:'start',value: 'vialidad' }, 
        { text: 'N.Ext', align:'start',value: 'numero_exterior' }, 
        { text: 'N.Int', align:'start',value: 'numero_interior' }, 
        { text: 'Cruzamientos', align:'start',value: 'cruzamiento_1' }, 
        { text: 'C.P.', align:'start',value: 'codigo_postal' },
        { text: 'Colonia', align:'start',value: 'colonia'},
        { text: 'Localidad,Municipio,Estado,Pais', align:'start',value: 'localidad' },
        { text: 'Estatus', align:'start',value: 'estatus'},
        { text: 'Opciones', align:'center', value: 'actions'}
      ],
      showDialogVenta: false,
      search: '',
      loading: false,
      sortBy: '',
      sortDesc: '',
      items_ventas: [],
      currentPage: 1,
      perPage: 20,
      options: {
        itemsPerPage:20,
        page:1
      },
      totalRegistros: 0,
      headerVentas:[ 
        { text: 'Folio', align:'start',value: 'referencia', width:200},
        { text: 'Fecha', align:'start',value: 'fecha' }, 
        { text: 'Nota', align:'start',value: 'notas' }, 
        { text: 'Importe', align:'start',value: 'sub_total' }, 
        { text: 'Total', align:'start',value: 'total' }, 
        { text: 'Opciones', align:'center', value: 'actions'}
      ],
      headerRazonesSociales:[ 
        { text: 'RFC', align:'start',value: 'rfc', width:200},
        { text: 'Razon social', align:'start',value: 'razon_social' },  
        { text: 'Regimen capital', align:'start',value: 'regimen_capital' },
        { text: 'Regimen fiscal', align:'start',value: 'regimen_fiscal' }, 
        { text: 'Fecha de inicio', align:'start',value: 'fecha_inicio' }, 
        { text: 'Representante legal', align:'start',value: 'representante_legal' }, 
        { text: 'Estatus', align:'start',value: 'estatus'},
        { text: 'Opciones', align:'center', value: 'actions'}
      ],
      headerBancarios:[ 
        { text: 'Banco', align:'start',value:'banco', width:200},
        { text: 'Cuenta', align:'start',value: 'cuenta' },  
        { text: 'CLABE', align:'start',value: 'clabe' },
        { text: 'Tarjeta', align:'start',value: 'tarjeta' }, 
        { text: 'Convenio', align:'start',value: 'sucursal' }, 
        { text: 'Swift', align:'start',value: 'swift' }, 
        { text: 'Estatus', align:'start',value: 'estatus'},
        { text: 'Opciones', align:'center', value: 'actions'}
      ],
      tab:0,
      showDialog: false,
      showDialogDireccion: false,
      showDialogEmpresa: false,
      showDialogBanco: false,
      //variable que almacena los datos del registro actual
      indexActual: 0,
      //variable que almacena el registro por default si existiera
      registroDefault: 0,   
      registroDatosPersonales:[],
      registroActualDireccion:{},
      registroActualRazonSocial: {},
      registroActualBanco: {},
      colonias: [],
      coloniaSeleccionada: {},
      localidadSelecionado: {},
      capturarColonia: false,
      capturarLocalidad: false,
      //variable que almacena la lista de registros actuales
      registros:[],
      registrosBancos:[],
      registrosBancosActual: 0,
      bancosSAT:[],
      regimenFiscal:[],
      usos_cfdi:[],
      formas_pago:[],
      metodos_pago:[],
      bSinRegistros:false,
      dpFecha: false,
      nombre:'',
      //variable que almacena el nivel del usuario para hacer validaciones
      nivel:2,
      direccion_id:null,
      direccion_actual_id: null,
      razon_social_id: null,
      isNewDireccion: false,  
      isNewRazonSocial: false, 
      showdialogDireccion: false, 
      isEditRazonSocial:false,
      rules: {}, 
      lista_precios: [],
      impuesto_perfil: null,
      impuestos_perfiles: [],
      onMobile:false,
      isNewVenta: false,
    }),
    mounted() {
    },
    methods: {
      getRegistro(){
        axios
        .get(this.URL(this.baseURL+'/'+this.id))
        .then(response => {
          this.registroActual = response.data;
          this.direccion_id = this.registroActual.direccion_id;
        })
        .catch(this.MSGBOX);
      },
      Guardar(){
        this.registroActual.direccion.nombre = "Direccion principal";
        console.log(this.registroActual);
        if (this.isNew){ 
              axios
                .post(this.URL(this.baseURL),this.registroActual)
                .then(response => { 
                  this.$emit('new',response.data);  
                })
                .catch(this.MSGBOX);
        } else {
          axios
            .put(this.URL(this.baseURL+"/"+this.id),this.registroActual)
            .then(response => {
                this.$emit('update',response.data);
                this.registroActual = {
                  datos_personales:{}
                };                 
  
            })
            .catch(this.MSGBOX);
        }
      },
      Close(){
        this.$emit('close');
      },
      AddVenta(){
        this.isNewVenta = true;
        this.showDialogVenta = true;
      },
      CloseVenta(venta){
        this.items_ventas.unshift(venta);
        this.showDialogVenta = false;
      },
      getVentasContacto(){
        axios
        .get(this.URL('/ventas','&contacto='+this.id))
        .then(response => {
          this.items_ventas = response.data;
          this.totalRegistros = this.items_ventas.length;
          console.log(this.items_ventas);
        })
        .catch(this.MSGBOX);

      },
      onUpdateDir(value){ 
        this.registroActual.direccion = value;
        console.log(this.registroActual.direccion);
      },
      onUpdateDirActual(value) {
        this.registroActualDireccion = value;
      },
    },
  watch: {
    show(val) {
      if(!val) return;
      this.tab = 0;
      if(this.isNew){
        this.registroActual = {};
        this.registroActual.direccion = {};
        return;
      }
      this.getRegistro();
    },
    tab(val) {
      switch(val){
        case 1: this.getVentasContacto(); break;
      }
    },
  }
};
</script>