import Vue from 'vue'
import VueSweetalert2 from 'vue-sweetalert2';
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from "axios";
import HToolbar from "@/components/h-toolbar.vue";
import HToolbarModal from "@/components/h-toolbar-modal.vue";
import HGrid from "@/components/h-grid.vue";

import 'sweetalert2/dist/sweetalert2.min.css';


Vue.component("HToolbar", HToolbar); 
Vue.component("HToolbarModal", HToolbarModal); 
Vue.component("HGrid", HGrid);

Vue.config.productionTip = false
Vue.use(VueSweetalert2);

axios.defaults.baseURL = "https://api.gasancira.com/api";
//axios.defaults.baseURL = "http://127.0.0.1:8000/api";
// Configurar Axios para enviar la cabecera Access-Control-Allow-Origin: *
axios.defaults.headers.common['Access-Control-Allow-Origin'] = '*';
//axios.defaults.params = {};
Vue.prototype.$axios = axios;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app')

Vue.prototype.SET_AXIOS_DEFAULT_PARAMS  = function () {
  axios.defaults.params['api_token'] = sessionStorage.getItem("token");
}
Vue.prototype.URL  = function (url,filtros='') {
  let variables = '?';
  if(localStorage.getItem("userData")){
    let userData = JSON.parse(localStorage.getItem("userData"));
    let timestamp = Date.now();
    let var_token = JSON.parse(localStorage.getItem("userData"))["token"];
    let var_user_id = JSON.parse(localStorage.getItem("userData"))["id"];
    let var_group_id = JSON.parse(localStorage.getItem("userData"))["group_id"];
    if(userData.expire_time >= timestamp){
      if(var_token) variables += 'token='+var_token;
      if(var_user_id) variables += '&user_id='+var_user_id;
      if((var_group_id != null && var_group_id != undefined) && var_group_id >= 0) variables += '&group_id='+var_group_id;
    }else{
      localStorage.removeItem("userData");
    }
  }
  //let url_complete = url + '?token=' + sessionStorage.getItem("token") + "&database_id=" + sessionStorage.getItem("DataBaseId") + filtros;
  let url_complete = url + variables + filtros;    
 return url_complete;
}
Vue.prototype.getDateTime  = function () {
  let ahora = new Date();
  let year = ahora.getFullYear();
  let mes = String(ahora.getMonth() + 1).padStart(2, '0');
  let dia = String(ahora.getDate()).padStart(2, '0');
  let hora = String(ahora.getHours()).padStart(2, '0');
  let minutos = String(ahora.getMinutes()).padStart(2, '0');
  let datetime = `${year}-${mes}-${dia} ${hora}:${minutos}`;
  return datetime;
}

Vue.prototype.URLT  = function (url,filtros='') {
  let variables = '?';
  if(localStorage.getItem("TransportistaData")){
    let TransportistaData = JSON.parse(localStorage.getItem("TransportistaData"));
    let timestamp = Date.now();
    let var_token = JSON.parse(localStorage.getItem("TransportistaData"))["token"];
    let var_user_id = JSON.parse(localStorage.getItem("TransportistaData"))["id"];
    if(TransportistaData.expire_time >= timestamp){
      if(var_token) variables += 'token='+var_token;
      if(var_user_id) variables += '&transportista_id='+var_user_id;
      if((var_group_id != null && var_group_id != undefined) && var_group_id >= 0) variables += '&group_id='+var_group_id;
    }else{
      localStorage.removeItem("TransportistaData");
    }
  }
  //let url_complete = url + '?token=' + sessionStorage.getItem("token") + "&database_id=" + sessionStorage.getItem("DataBaseId") + filtros;
  let url_complete = url + variables + filtros;    
 return url_complete;
}
Vue.prototype.RULES = () => {
  return {
    valor: [val => (val || '').length > 0 || 'Requerido'],
    valor8: [val => (val || '').length > 8 || 'Requerido (8 letras)'],
    valor10: [val => (val || '').length > 10 || 'Requerido (10 letras)'],
    valor15: [val => (val || '').length > 15 || 'Requerido (15 letras)'],
    telefono: [
      v => !!v || "Teléfono requerido",
      v =>
        (v && v.length === 10) || "Debe contener 10 dígitos"
    ],
    correo: [
      value => /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value) || "Correo inválido (example@gmail.com)"
    ],
    fecha: [
      value => /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/.test(value) || "Fecha inválida (2022-01-01)"
    ]
  };
};

Vue.prototype.FORMAT_CURRENCY = function (val) {
  // Create our number formatter.
  let formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'MXN',
  });

  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
  return formatter.format(val); 
}

Vue.prototype.AddComas  = function (numero) {
  return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
Vue.prototype.MSGBOX = function (error) {

  if (error.message && !error.response){
    Vue.swal.fire({ icon: "error", title: "Error", text: error.message });
    return;
  }
   
  let response = error.response;
  let data = response.data;
  let status = response.status;

  switch(status){
    case 200: Vue.swal.fire({ icon: "success", title: "Information", text: data.message }); break;
    case 400: Vue.swal.fire({ icon: "warning", title: "AVISO", text: data.error }); break;
    case 401: Vue.swal.fire({ icon: "warning", title: "Acceso Denegado", text: "YOU ARE NOT AUTHORIZED TO ACCESS THIS ROUTE" }); localStorage.removeItem("userData"); break;
    case 403: Vue.swal.fire({ icon: "warning", title: "OPERATION REJECTED", text: "v" }); break;
    case 422: Vue.swal.fire({ icon: "warning", title: "AVISO", text: data.error }); break;
    default: Vue.swal.fire({ icon: "error", title: "Error", text: data.message }); break;
  }
  return status;
}
