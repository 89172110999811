<template>
  <span>
    <v-toolbar  color="primary">
      <v-btn icon dark @click="Cerrar()" >
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn @click="Guardar()">
        <v-icon>mdi-playlist-plus</v-icon>Guardar
      </v-btn>
      <v-spacer></v-spacer>
    </v-toolbar>
  </span>
</template>
<script>
import axios from 'axios'; 
import Vue from 'vue'
export default {
    props:[
      'id',
      'isNew'
    ],
    data: () => ({
      drawer: false,
      pantalla_nombre: '',
    }),
    mounted() {
    },
    methods: {
      Guardar() { this.$emit('guardar'); },
      Cerrar(){   this.$emit('close'); }
    },
    watch: {
    },
};
</script>