<template>
    <span>
      <v-data-table
        dense
        :headers="headers"
        :items="items"
        @click:row="onClickRow"
        item-key="name"
        class="elevation-1"
        hide-default-footer
        disable-pagination
        disable-items-per-page
        v-click-outside="onClickOutside"
      >
        <template v-slot:[`item.cantidad`]="{ item }">
          <v-text-field
            v-if="item.selected==true"
            dense
            reverse  
            outlined  
            light 
            hide-details
            class="caption"
            v-model="item.cantidad"
            @focus="$event.target.select()"
            @change="CalcularItems(item);" 
          >
          </v-text-field>
          <span v-else class="caption" >
            {{item.cantidad}} Litros
          </span>
        </template>
        <template v-slot:[`item.precio`]="{ item }">
          <span class="caption">{{FORMAT_CURRENCY(item.precio)}}</span>
        </template>
        <template v-slot:[`item.descuento`]="{ item }">
          <span class="caption">{{FORMAT_CURRENCY(item.descuento)}}</span>
        </template>
        <template v-slot:[`item.impuesto`]="{ item }">
          <span class="caption">{{FORMAT_CURRENCY(item.impuesto)}}</span>
        </template>
        <template v-slot:[`item.importe`]="{ item }">
          <span class="caption">{{FORMAT_CURRENCY(item.importe)}}</span>
        </template>
        <template v-slot:[`body.append`]>
          <v-btn text @click="AddProducto()"  >
            <v-icon title="Añadir">mdi-playlist-plus</v-icon>
          </v-btn>
      </template>
    </v-data-table>
    </span>
  </template>
  <script>
  import axios from 'axios'; 
  import Vue from 'vue'
  export default {
      props:[
        'id',
        'isNew',
        'items'
      ],
      data: () => ({
        headers: [
          { text: 'Producto', align:'start',value: 'concepto', width:200 },
          { text: 'Cantidad', align:'end',value: 'cantidad' }, 
          { text: 'Unidad', align:'center',value: 'unidad_id' }, 
          { text: 'Precio', align:'end',value: 'precio' }, 
          { text: 'Descuento', align:'end',value: 'descuento' }, 
          { text: 'Impuestos', align:'end',value: 'impuesto' }, 
          { text: 'Total', align:'end',value: 'total' }, 
          { text: 'Opciones', align:'end', value: 'actions'}
        ],
        itemActual: {},
      }),
      mounted() {
      },
      methods: {
        onClickRow(obj){
          let ix = this.items.indexOf(obj);
          if(obj.selected == true) return;

          for(let i = 0; i < this.items.length; i++) {
            this.items[i].selected = false;
          }
          obj.selected = true;
          console.log(ix+' new: '+obj.selected);
          this.itemActual = obj;
          this.$forceUpdate();
        },
        CalcularItems(item){
          if (!item.cantidad) item.cantidad = 1;
          if (!item.precio) item.precio = 0;
          let total = parseFloat(item.precio) * parseFloat(item.cantidad);
          item.total = total;
          this.onChange();
        },
        onChange() {  
          this.$emit('update',this.items);
        }, 
        AddProducto(){
        axios
        .get(this.URL('/productos/1'))
        .then(response => {
          console.log('enviando');
          let prod = response.data;
          let item = {
            producto_id: prod.id,  
            concepto: prod.nombre, //prod.descripcion
            notas: null,
            cantidad: 1,
            precio: prod.precio,
            descuento_importe:0,
            descuentos:[],
            impuestos: [],// aImpuestos,
            precios_venta: prod.precios_venta,
            costo: prod.precio, //deberia ser ultimo_costo
            sub_total: prod.precio, // deberia ser importe,
            impuesto: 0,
            retencion: 0,
            total: prod.precio, //importe + tImp + tRet ,
            unidad: 'Litros',
            unidad_id: 1,
            selected: false
          };
          this.$emit('add',item);
        })
        .catch(this.MSGBOX);
        return;
          let item = {
            producto_id: prod.id,  
            concepto: prod.descripcion,
            notas: null,
            cantidad: 1,
            precio: prod.precio,
            descuento_importe:0,
            descuentos:[],
            impuestos: aImpuestos,
            precios_venta: prod.precios_venta,
            costo:prod.ultimo_costo,
            sub_total: importe,
            impuesto: tImp,
            retencion: tRet,
            total: importe + tImp + tRet ,
            unidad: prod.unidad,
            unidad_id: prod.unidad_id,
            selected: false
        }; 

        //console.log(item);

        return item;
        },
        onClickOutside(){
          for(let i = 0; i < this.items.length; i++) {
            this.items[i].selected = false;
            this.$forceUpdate();
          }
        }

      },
      watch: {
      },
  };
  </script>
  <style scoped>

    #input-cantidad {
      border: 1px dashed rgba(0,0,0, .4);
    }
  </style>