<template>
    <span>
  <v-dialog v-model="show" persistent :fullscreen="fullscreen" :max-width="max_width" overlay transition="dialog-bottom-transition" scrollable>
    <v-card>
      <v-card-title>
        Ingresar
        <v-spacer></v-spacer>
        <v-icon class="mr-3" @click="Close">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text> 
        <v-form ref="form" v-model="valid">
          <div class="text-center">
          <v-text-field
            v-model="transport_id"
            dense
            outlined
            label="ID"
            prepend-inner-icon="mdi-email"
            class="pt-4"
          ></v-text-field>
          <v-text-field
            v-model="password"
            dense
            outlined
            label="Contraseña"
            placeholder="Escribe una contraseña"
            :append-icon="showpass ? 'mdi-eye' : 'mdi-eye-off'"
            :type="showpass ? 'text' : 'password'"
            @click:append="showpass = !showpass"
          ></v-text-field>
          <v-checkbox
            class="mt-n4"
            v-model="save_seccion"
            label="Mantener seccion iniciada"
          ></v-checkbox>
          <v-btn class="mx-auto mr-2" color="primary" @click="Login()">
            Iniciar Sección
          </v-btn>
          <v-btn class="mx-auto ml-2" color="primary" @click="Register()">
            Registrarse
          </v-btn>
          </div>
        </v-form>
      </v-card-text>
    

    </v-card>

  </v-dialog>
    </span>
</template>
<script>
import Vue from 'vue'
export default {
    props:[
      'show'
    ],
    data: () => ({
      registroActual:{},
      transport_id: '',
      password: '',
      valid: true,
      fullscreen: false,
      save_seccion: true,
      showpass: false,
      max_width: 300,
      renameRules: [
        v => !!v || "This field is required",
        v => /^[a-zA-Z0-9\s]+$/.test(v) || "Only English alphabet characters, numbers and spaces are allowed.",
        v => (v && (v.length >= 4 && v.length <= 64)) || "Must contain at least 4 characters"
        ],
      isVertical: true
    }),
    mounted() {
      var width = this.$vuetify.breakpoint.width;
      console.log(width);
      this.fullscreen = width < 600 ? true : false;
      if(width >= 600 && width < 700){        this.max_width = 300; this.isVertical = true;
      }else if(width >= 700 && width < 800){  this.max_width = 420; this.isVertical = true;
      }else if(width >= 800 && width < 900){  this.max_width = 500; this.isVertical = false;
      }else{                                  this.max_width = 900; this.isVertical = false;
      }
    },
    methods: {
        Close(){
            this.$emit('close');
            return;
        },
        Login(){
          this.registroActual.transport_id = this.transport_id;
          this.registroActual.password = this.password; 
          this.$emit('login',this.registroActual);
        },
    },
    watch: {
    },
};
</script>
<style scoped>
.border-left {
  border-left: 1px solid #ccc;
}

.border-md-top {
  border-top: 1px solid #ccc;
}
</style>