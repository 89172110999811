<template>
    <span>
      <div class="d-flex">
            <v-navigation-drawer v-if="drawer"
              v-model="drawer"
              dense
              mini-variant.sync="false"
            >
              <v-list>
                <v-list-item v-for="(item, index) in ModuleItems" :key="index" @click="selectItem(item)">
                  <v-list-item-title class="caption" dense>{{ item.text }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-navigation-drawer>
            <v-main style="padding: 0px 0px 0px 0px;">
                <v-data-table
                  :headers="headers"
                  item-key="id"
                  :search="search"
                  :loading="loading"
                  class="elevation-1 pt-n4"
                  multi-sort
                  :sort-by="sortBy"
                  :sort-desc="sortDesc"
                  :items="items"
                  :page="currentPage"
                  :items-per-page="perPage"
                  :options.sync="options"
                  items-per-page-options="[20,50,100,-1]"
                  :server-items-length="totalRegistros" 
                  :footer-props="{
                    showFirstLastPage: true,
                    firstIcon: 'mdi-chevron-double-left',
                    lastIcon: 'mdi-chevron-double-right',
                    prevIcon: 'mdi-chevron-left',
                    nextIcon: 'mdi-chevron-right'
                  }"
                >
                <template v-slot:top>
                  <v-toolbar flat color="primary">
                  <v-card-title class="white--text">{{pantalla_nombre}}</v-card-title>
                    <v-spacer></v-spacer>
                  <v-text-field
                    color="white"
                    v-model="search"
                    append-icon="mdi-magnify"
                    label="Buscar"
                    single-line
                    hide-details
                    solo
                  ></v-text-field>
                  </v-toolbar>
                </template>
                <template v-slot:item.nombre="{ item }">
                  <span class="caption">{{item.nombre}}</span>
                </template>
                <template  v-slot:[`item.actions`]="{ item }">
                  <v-icon @click="Editar(item.id)">mdi-pencil</v-icon>
                  <v-icon @click="Eliminar(item.id)">mdi-delete</v-icon>
                </template>
              </v-data-table>
            </v-main>
      </div>
      
    </span>
    
</template>
<script>
import axios from 'axios'; 
import Vue from 'vue'
export default {
    components: {
	  },
    props:[
      'user_id',
      'pantalla',
      'drawer'
    ],
    data: () => ({
      ApiURL: '',
      pantalla_nombre: '',
      mini: true,
      items:[],
      ModuleItems: [],
      registros:[],
      loading: false,
      currentPage: 1,
      perPage: 20,
      totalItems: null,
      search:'',
      search_name:null,
      headers:[
        { text: 'Nombre', align: 'start', sortable: false, value: 'nombre' },
        { text: 'Opciones', align: 'end', value: 'actions' }
      ],
      sortBy: 'id',
      sortDesc: true,
      totalRegistros: 0,
      options: {
        itemsPerPage:20,
        page:1
      },
    }),
    mounted() {
      this.getRegistros();
    },
    methods: {
      getRegistros(){
        axios
          .get(this.URL(this.baseURL))
          .then(response => {
            this.items = response.data;
            this.totalRegistros = this.items.length;
          })
          .catch(this.MSGBOX);
      },
      Editar(id){
        this.$emit('editar',id);
      },
      Eliminar(id){
        this.$emit('eliminar',id);
      },
      Nuevo(value){
        this.items.unshift(value);
      },
      Update(value){
        let ix = this.items.findIndex(item => item.id === value.id);
        this.$set(this.items, ix, value);
      }
    },
    watch: {
      user_id(val) {
      },
      pantalla(val){
        switch(val){
          case 'transportistas':
            this.pantalla_nombre = 'Transportista';
            this.ModuleItems = [
              { text: 'Mi Cuenta', icon: 'mdi-view-dashboard' },
              { text: 'Camiones', icon: 'mdi-view-dashboard' },
            ]; 
            break;
          case 'ventas':
            this.pantalla_nombre = 'Ventas';
            this.ModuleItems = [
              { text: 'Ventas', icon: 'mdi-view-dashboard' },
              { text: 'Ventas Programadas', icon: 'mdi-view-dashboard' },
            ]; 
            break;
          case 'contactos':
          this.baseURL = '/contactos';
            this.pantalla_nombre = 'Contactos';
            this.ModuleItems = [
              { text: 'Contactos', icon: 'mdi-view-dashboard' },
            ]; 
            break;
          case 'compras':
            this.pantalla_nombre = 'Compras';
            this.ModuleItems = [
              { text: 'Compras', icon: 'mdi-view-dashboard' },
            ]; 
            break;
        }
        this.ApiURL = val;
        this.getRegistros();
        
      }
    },
};
</script>
<style>
.pr-0 {
  padding-right: 0 !important;
}
.no-padding {
  padding: 0 !important;
  margin: 0 !important;
}
.align-center {
  display: flex;
  align-items: center;
}
</style>