<template>
    <v-container fluid>    
      <v-row dense >
        <v-col v-if="showName==true" cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="DescripciÃ³n" @change="onChange(false)" dense persistent-hint v-model="registroActual.nombre"></v-text-field>
        </v-col>
         <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Vialidad" @change="onChange(false)" dense persistent-hint v-model="registroActual.vialidad"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Numero Exterior" @change="onChange(false)" dense persistent-hint v-model="registroActual.numero_exterior"></v-text-field>
        </v-col>
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Numero Interior" @change="onChange(false)" dense persistent-hint v-model="registroActual.numero_interior"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense >
        
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Cruzamiento 1" @change="onChange(false)" dense persistent-hint v-model="registroActual.cruzamiento_1"></v-text-field>
        </v-col>
  
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Cruzamiento 2" @change="onChange(false)" dense persistent-hint v-model="registroActual.cruzamiento_2"></v-text-field>
        </v-col>
      </v-row>
      <v-row dense>
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Codigo Postal" @change="onChange(true)" dense persistent-hint v-model="registroActual.codigo_postal"></v-text-field>
        </v-col>
         <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-autocomplete 
            v-if="!capturarColonia"
            dense 
            label="Colonia" 
            :items="colonias"  
            v-model="coloniaSeleccionada"  
            item-text="nombre" 
            item-value="id" 
            return-object
            append-outer-icon="mdi-pencil" 
            @change="()=>{
              registroActual.colonia_id = coloniaSeleccionada.c_Colonia;
              registroActual.colonia = coloniaSeleccionada.nombre;
              this.onChange(false);
            }"
            @click:append-outer="()=>{capturarColonia=true;coloniaSeleccionada.c_Colonia='';}"
            persistent-hint/> 
            <v-text-field 
              v-if="capturarColonia" 
              label="Colonia"  
              dense 
              persistent-hint 
              append-outer-icon="mdi-form-select"  
              @change="()=>{coloniaSeleccionada={};this.onChange();}"
              @click:append-outer="capturarColonia=false"
              v-model="registroActual.colonia">
            </v-text-field>
        </v-col>
      </v-row>
      <v-row dense >
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
           <v-text-field   
            :label="`Localidad (${registroActual.localidad_id})`"  
            dense 
            persistent-hint 
            v-model="registroActual.localidad"  
            ></v-text-field>
        </v-col>
        
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Municipio" @change="onChange(false)" dense persistent-hint v-model="registroActual.municipio" ></v-text-field>
        </v-col>
  
      </v-row>
      <v-row dense>
        <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Entidad" @change="onChange(false)" dense persistent-hint v-model="registroActual.entidad"></v-text-field>
        </v-col>
  
         <v-col  cols="12" xs="12" sm="6" md="6" lg="4" xl="3">
          <v-text-field  label="Pais" @change="onChange(false)" dense persistent-hint v-model="registroActual.pais"></v-text-field>
        </v-col>
      </v-row> 
    </v-container>
  </template>
  <script>
  import Vue from "vue";
  import axios from 'axios';
  
  export default {
    props:[ 
      'id',
      'isNew',
      'showName'
    ],
    data: () => ({
      panel: [0, 1],
      disabled: false,
      readonly: false,
      baseURL:"/direcciones",
      registroActual:{},
      hasSaved: false,
      capturarColonia:false,
      isEditing: null,
      model: null,
      showDialog: false,
      coloniaSeleccionada:false,
      //variable que almacena los datos del registro actual
      indexActual: 0,
      //variable que almacena el registro por default si existiera
      registroDefault: 0,      
      //variable que almacena la lista de registros actuales
      registros:[],
      colonias: [],
      bSinRegistros:false,
      dpFecha: false,
      //variable que almacena el nivel del usuario para hacer validaciones
      nivel:2,
      colonia_id: null,
      localidad_id: null,
      entidad_id: null,
      municipio_id: null,
      pais_id: null,
      isNewDireccion: false, 
      idDireccionActual: null, 
      inputRules: [
        v => !!v || "Este campo es requerido",
        v => (v && v.length >= 0) || "Debe contener almenos 8 caracteres"
      ]
      
    }),
   
    mounted() {
      if (this.id!=null) { 
          this.getRegistro();
      }
    },
    methods: {
      save(){
          return;
      this.isEditing = !this.isEditing
      this.hasSaved = true
      },
      update(){},
  onClose(){
    return;
    this.$emit('close');
  },
  getRegistro() {
    axios
    .get(this.URL(this.baseURL+'/'+this.id))
    .then(response =>{
      this.registroActual = response.data;
      //console.log(this.registroActual);
      //this.capturarColonia = this.registroActual.colonia_id != '';
      //this.buscarCP(true);
        
    })
    .catch(error => {

      Vue.swal.fire({
        icon: "error",
        title: "Ooops!",
        text: error.response
      }); 
    });

  },
  Guardar(){
    return;

    if (this.isNew)
    {
        {
                      
            axios
              .post(`${this.baseURL}?api_token=${sessionStorage.getItem("token")}`,this.registroActual)
              .then(response => {

                this.$emit('new');
                  
              })
              .catch(error => {

                Vue.swal.fire({
                    icon: "error",
                    title: "Ooops!",
                      text: error.response.data.error
                  });

              });

        };
      
    } else {   
      axios
        .put(`${this.baseURL}/${this.id}?api_token=${sessionStorage.getItem("token")}`,this.registroActual)
        .then(response => {

            this.$emit('update');
            //this.registroActual = {};                 

            Vue.swal.fire({
              type: "Success",
              title: "DirecciÃ³n Actualizada"
          
            });

        })
        .catch(error => {

          Vue.swal.fire({
              icon: "error",
              title: "Ooops!",
              text: error.response.error
            });

        }); 
        
  }    
  },
  onChange(esCodigoPostal=false) {

    //if (esCodigoPostal) {
    //  this.buscarCP(false);
    //} else { 
      this.$emit('update',this.registroActual);
    //}

  },
  buscarCP(soloColonias=false) {
    return;

    if (this.registroActual.codigo_postal==null || this.registroActual.codigo_postal=='') return true;

    axios
      .get(`codigos_postales/${this.registroActual.codigo_postal}?api_token=${sessionStorage.getItem("token")}`)
      .then(response => {

        if (soloColonias==false) {
            
          this.registroActual.entidad_id = response.data.estado.c_Estado;
          this.registroActual.entidad = response.data.estado.nombre;

          if (response.data.c_Localidad != '') {
            this.registroActual.localidad_id = response.data.localidad.c_Localidad;
            this.registroActual.localidad = response.data.localidad.nombre;
          }
          
          this.registroActual.municipio_id = response.data.municipio.c_Municipio;
          this.registroActual.municipio = response.data.municipio.nombre;
  
          this.registroActual.pais_id = response.data.pais.id;
          this.registroActual.pais = response.data.pais.nombre;

          this.$emit('update',this.registroActual);

        }

        this.colonias = response.data.colonias;

        /*if (JSON.stringify(response.data) != '{}') { 
          this.registroActualDireccion =  response.data.direccion;

        }  */

      })
      .catch(error => {

          //console.log(error.response);

          Vue.swal.fire.fire({
            icon: "error",
            title: "Ooops!",
            text: error.response.data.error
          });
        
      });

  },
    },
    watch: {
      id: function (){
        if(!this.id) {
          this.registroActual = {};
          return;
        }
        this.getRegistro();
      },
      show : function () {
        this.$nextTick();
      }
     
    }
  };
  </script>
  
  <style scoped>
  
  </style>