var render = function render(){var _vm=this,_c=_vm._self._c;return _c('span',[_c('div',{staticClass:"d-flex"},[(_vm.drawer)?_c('v-navigation-drawer',{attrs:{"dense":"","mini-variant.sync":"false"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[_c('v-list',_vm._l((_vm.ModuleItems),function(item,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.selectItem(item)}}},[_c('v-list-item-title',{staticClass:"caption",attrs:{"dense":""}},[_vm._v(_vm._s(item.text))])],1)}),1)],1):_vm._e(),_c('v-main',{staticStyle:{"padding":"0px 0px 0px 0px"}},[_c('v-data-table',{staticClass:"elevation-1 pt-n4",attrs:{"headers":_vm.headers,"item-key":"id","search":_vm.search,"loading":_vm.loading,"multi-sort":"","sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"items":_vm.items,"page":_vm.currentPage,"items-per-page":_vm.perPage,"options":_vm.options,"items-per-page-options":"[20,50,100,-1]","server-items-length":_vm.totalRegistros,"footer-props":{
                showFirstLastPage: true,
                firstIcon: 'mdi-chevron-double-left',
                lastIcon: 'mdi-chevron-double-right',
                prevIcon: 'mdi-chevron-left',
                nextIcon: 'mdi-chevron-right'
              }},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"primary"}},[_c('v-card-title',{staticClass:"white--text"},[_vm._v(_vm._s(_vm.pantalla_nombre))]),_c('v-spacer'),_c('v-text-field',{attrs:{"color":"white","append-icon":"mdi-magnify","label":"Buscar","single-line":"","hide-details":"","solo":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)]},proxy:true},{key:"item.nombre",fn:function({ item }){return [_c('span',{staticClass:"caption"},[_vm._v(_vm._s(item.nombre))])]}},{key:`item.actions`,fn:function({ item }){return [_c('v-icon',{on:{"click":function($event){return _vm.Editar(item.id)}}},[_vm._v("mdi-pencil")]),_c('v-icon',{on:{"click":function($event){return _vm.Eliminar(item.id)}}},[_vm._v("mdi-delete")])]}}],null,true)})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }