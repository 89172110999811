<template>
  <span>
    <v-app-bar 
      dense 
      dark
      color="primary"
      title="Application bar"
    >
      <v-app-bar-nav-icon @click="toggleDrawer">
      </v-app-bar-nav-icon>
      <v-toolbar-title>{{pantalla_nombre}}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn @click="OpenDialog(true)">
        <v-icon>mdi-playlist-plus</v-icon>Nuevo
      </v-btn>
      <v-spacer></v-spacer>
    </v-app-bar>
  </span>
</template>
<script>
import axios from 'axios'; 
import Vue from 'vue'
export default {
    props:[
      'user_id',
      'pantalla'
    ],
    data: () => ({
      drawer: false,
      pantalla_nombre: '',
      items: [
        { text: 'Opción 1', icon: 'mdi-view-dashboard' },
        { text: 'Opción 2', icon: 'mdi-account' },
        { text: 'Opción 3', icon: 'mdi-settings' }
      ],
      mini: true,
      registros:[],
    }),
    mounted() {
    },
    methods: {
      toggleDrawer() {
        this.drawer = !this.drawer;
        this.$emit('showsunbnemu',this.drawer);
      },
      OpenDialog(value){
        if(!this.user_id || this.user_id == 0) return;
        if(!this.pantalla || this.pantalla == 0) return;
        this.$emit('opendialog',value);
      }
    },
    watch: {
      user_id(val) {
      },
      pantalla(val){
        switch(val){
          case 'ventas': this.pantalla_nombre = 'Ventas'; break;
          case 'contactos': this.pantalla_nombre = 'Contactos'; break;
          case 'compras': this.pantalla_nombre = 'Compras'; break;
          case 'transportistas': this.pantalla_nombre = 'Transportistas'; break;
            break;
        }
      }
    },
};
</script>