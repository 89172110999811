<template>
  <span>
  <v-navigation-drawer 
      v-model="drawer"
      :mini-variant.sync="mini"
      permanent
      app
    >
      <v-list-item class="px-2">
        <v-list-item-avatar>
          <v-img src="https://randomuser.me/api/portraits/men/85.jpg"></v-img>
        </v-list-item-avatar>
        <v-list-item-title>Ramiro Garza</v-list-item-title>

        <v-btn icon @click.stop="mini = !mini" >
          <v-icon>mdi-chevron-left</v-icon>
        </v-btn>
      </v-list-item>

      <v-divider></v-divider>

      <v-list dense>
        <v-list-item
          v-for="item in items"
          :key="item.title"
          link
        >
          <v-list-item-icon>
            <v-icon>{{ item.icon }}</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title @click="goAction(item.action)">{{ item.title }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
  </v-navigation-drawer>

  <h-toolbar
    :user_id="user_id"
    :pantalla="pantalla"
    @showsunbnemu="showsunbnemu"
    @opendialog="opendialog"
  ></h-toolbar>

  <v-main style="padding: 0px 0px 0px 0px;">
    <v-container fluid >
      <page-constructor
        ref="RefContructor"
        :user_id="user_id"
        :pantalla="pantalla"
        :drawer="drawer_menu"
        @editar="editar"
        @eliminar="eliminar"
      />
      </v-container>
  </v-main>
  
    <component
      ref="DialogControl"
      v-bind:is="currentDialog"
      :show="show"
      :isNew="isNew"
      :id="registro_id"
      :pantalla="pantalla"
      @close="CloseDialog()"
      @new="Nuevo"
      @update="Update"
    />
    <dialog-user-login
    :show="dialog_userlogin"
    @close="dialog_userlogin=false"
    @login="login"
    @showregister="showregister"
    />
    <dialog-transport-login
    :show="dialog_transportlogin"
    @close="dialog_transportlogin=false"
    @login="login"
    @showregister="showregister"
    />
    <dialog-user-register
      :show="dialog_userregister"
      @close="dialog_userregister=false"
      @login="login"
      @register="register"
      @showregister="showregister"
    />
  </span>
</template>

<script>
  import Vue from "vue";
  import axios from 'axios';

  import DialogUserLogin from "@/components/dialogs/DialogUserLogin.vue";
  import DialogTransportLogin from "@/components/dialogs/DialogTransportLogin.vue";
  import DialogUserRegister from "@/components/dialogs/DialogUserRegister.vue";
  import PageConstructor from "@/components/page-constructor.vue";
  import DialogContactos from "@/components/dialogs/DialogContactos.vue";


  export default {
    name: 'Main',
    components: {
      DialogUserLogin,
      DialogTransportLogin,
      DialogUserRegister,
      PageConstructor,
      DialogContactos
	  },

    data: () => ({
      currentDialog: null,
      user_id: 0,
      registro_id: 0,
      transportista_id: 0,
      pantalla: '',
      delay_pantalla: '',
      isNew: false,
      isUserLoggin: false,
      dialog_userlogin: false,
      dialog_transportlogin: false,
      dialog_userregister: false,
      drawer: true,
      drawer_menu: false,
      show: false,
      
        items: [
          { title: 'Inicio', icon: 'mdi-home-city', action: 'GoHome', need_user_access: false,  },
          { title: 'Mi Cuenta', icon: 'mdi-account', action: 'GoMyAccount', need_user_access: false },
          { title: 'Transportistas', icon: 'mdi-truck-cargo-container', action: 'GoTransportista', need_user_access: false },
          { title: 'Contactos', icon: 'mdi-account-details', action: 'GoContactos', need_user_access: true },
          { title: 'Ventas', icon: 'mdi-currency-usd', action: 'GoVentas', need_user_access: true },
          { title: 'Compras', icon: 'mdi-cart', action: 'GoCompras', need_user_access: true },
          //{ title: 'Reportes', icon: 'mdi-account-group-outline', action: 'GoReportes', need_user_access: true },
        ],
        mini: true,
      
    }),
    mounted() {
      this.user_id = null;
      this.user_name = null;
      this.token = null;
      //Obtener Datos del Usuario
      if(localStorage.getItem("userData") && JSON.parse(localStorage.getItem("userData")) !== null){
        this.userData = JSON.parse(localStorage.getItem("userData"));
        let timestamp = Date.now();
        if(this.userData.expire_time < timestamp){
          this.user_id = null;
          this.user_name = null;
        }else{
          this.user_id = this.userData.id;
          this.user_name = this.userData.user_name;
          this.token = this.userData.token;
        }
      }
      //Obtener Datos del Transportista
      if(localStorage.getItem("TransportistaData") && JSON.parse(localStorage.getItem("TransportistaData")) !== null){
        this.transportistaData = JSON.parse(localStorage.getItem("TransportistaData"));
        let timestamp = Date.now();
        if(this.transportistaData.expire_time < timestamp){
          this.transportista_id = null;
          this.user_name = null;
        }else{
          this.transportista_id = this.transportistaData.id;
          this.user_name = this.transportistaData.user_name;
          this.transportista_token = this.transportistaData.token;
        }
      }
    },
    methods: {
      Close(){
        this.dialog_userlogin = false;
        this.dialog_userregister = false;
        return;
      },
      CloseDialog(){
        this.show = !this.show;
      },
      showsunbnemu(value){
        this.drawer_menu = value;
      },
      opendialog(val){
        
        
        this.isNew = val;
        this.show = !this.show;
      },
      login(value){
        
        axios
        .post('login/login',value)
        .then(response => {
          
          let user = response.data.user;
          
          let timestamp = Date.now();
          let threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
          timestamp += threeDaysInMilliseconds;
          user.expire_time = timestamp;
          localStorage.setItem("userData", JSON.stringify(user));
          this.user_id = user.id;
          this.user_name = user.user_name;
          this.token = user.token;
          if(!user.active){
            Vue.swal.fire({ icon: "warning", text: "Requieres autorizacion" });
            return;
          }
          Vue.swal.fire({ icon: "success", text: "Bienvenido "+user.name });
          this.dialog_userlogin = false;
          this.dialog_userregister = false;
        })
        .catch(this.MSGBOX);
      },
      logintrans(value){
        
        axios
        .post('login/login',value)
        .then(response => {
          
          let user = response.data.user;
          
          let timestamp = Date.now();
          let threeDaysInMilliseconds = 3 * 24 * 60 * 60 * 1000;
          timestamp += threeDaysInMilliseconds;
          user.expire_time = timestamp;
          localStorage.setItem("userData", JSON.stringify(user));
          this.user_id = user.id;
          this.user_name = user.user_name;
          this.token = user.token;
          if(!user.active){
            Vue.swal.fire({ icon: "warning", text: "Requieres autorizacion" });
            return;
          }
          Vue.swal.fire({ icon: "success", text: "Bienvenido "+user.name });
          this.dialog_userlogin = false;
          this.dialog_userregister = false;
        })
        .catch(this.MSGBOX);
      },
      register(value){
        axios
        .post('login/register',value)
        .then(response => {
          
          this.dialog_userlogin = true;
          this.dialog_userregister = false;
        })
        .catch(this.MSGBOX);
      },
      showregister(){
        this.dialog_userlogin = false;
        this.dialog_userregister = true;
      },
      goAction(value){
        switch(value){
          case 'GoHome': this.showdialogLogin = true; break;
          case 'GoMyAccount': this.showdialogServer = true; break;
          case 'GoDashBoard': 
            if(this.getUserID(this.user_id))
              this.pantalla = 'administracion';
            else
            this.delay_pantalla = 'administracion';
            break;
          case 'GoContactos': 
            if(this.getUserID(this.user_id))
              this.pantalla = 'contactos';
            else
              this.delay_pantalla = 'contactos';
            break;
          case 'GoTransportista': this.getTransportistaID(this.transportista_id); this.pantalla = 'contactos'; break;
          case 'GoVentas': this.getUserID(this.user_id); this.pantalla = 'ventas'; break;
          case 'GoCompras': this.getUserID(this.user_id); this.pantalla = 'compras'; break;
          //case 'GoReportes': this.getUserID(this.user_id); this.pantalla = 'reportes'; break;
        }
      },
      getUserID(value){
        if(!value || value == 0){
          this.dialog_userlogin = true;
          return false;
        }
        return true;
      },
      getTransportistaID(value){
        if(!value || value == 0){
          this.dialog_transportlogin = true;
          return false;
        }
        return true;
      },
      Nuevo(value){
        this.$refs.RefContructor.Nuevo(value);
        this.show = !this.show;
      },
      Update(value){
        this.$refs.RefContructor.Update(value);
        this.show = !this.show;
      },
      editar(id){
        this.registro_id = id;
        this.isNew = false;
        this.show = true;
      },
      eliminar(id){

      }
    },
    watch: {
      user_id(val) {
        if(!val){
          this.isUserLoggin = false;
          return;
        }
        this.isUserLoggin = true;
        if(this.delay_pantalla != '')
          this.pantalla = this.delay_pantalla;
      },
      pantalla(value){
        switch(value){
          case 'contactos': this.currentDialog = DialogContactos;
        }
      }
    },
  }
</script>
