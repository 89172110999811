<template>
    <v-dialog v-model="show" persistent fullscreen hide-overlay transition="dialog-bottom-transition" scrollable>
        <v-card>
            <h-toolbar-modal   
              titulo = "Nota de venta"
              :id = "id"
              @close = "Close()"
              @guardar = "Guardar()"
            />
          <v-card-text>
            <v-container>
              <v-form ref="form" dense>
                <v-row dense class="pt-4" >
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="2">
                    <v-autocomplete 
                    dense 
                    label="Sucursal" 
                    :items="sucursales" 
                    v-model="sucursal_id"  
                    item-text="nombre" 
                    item-value="id" 
                    persistent-hint
                    /> 
                  </v-col>
                  <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-autocomplete 
                    dense 
                    label="Serie"
                    :items="series"
                    v-model="serie"
                    item-text="serie"
                    item-value="serie"
                    persistent-hint
                    :readonly="!isNew"
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2">
                    <v-text-field 
                      dense 
                      disabled
                      text  
                      label="Folio"
                      v-model="registroActual.folio">
                    </v-text-field>
                  </v-col>
                  <v-col cols="12" xs="12" sm="2" md="2" lg="2" xl="2" >
                    <v-menu ref="menu1" 
                      v-model="dpFecha" 
                      :close-on-content-click="false"
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto"
                      >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field 
                        dense 
                        clearable 
                        :rules="RULES.fecha"
                        v-model="registroActual.fecha"  
                        label="Fecha" 
                        persistent-hint
                        v-bind="attrs" v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="registroActual.fecha" 
                      no-title 
                      @input="dpFecha = false"
                      >
                    </v-date-picker>
                  </v-menu>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3">
                     <v-autocomplete 
                      dense  
                      v-model="contacto_id" 
                      label="Contacto" 
                      :items="contactos" 
                      item-text="nombre_completo" 
                      item-value="id"
                      :disabled="items.length>0 || export_contacto_id>0"
                    >
                    </v-autocomplete>
                  </v-col>
                   <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3" >
                    <v-autocomplete 
                      dense  
                      label="Métodos de Pago" 
                      :items="metodos_pago" 
                      v-model="metodo_pago"  
                      item-text="descripcion" 
                      item-value="id" 
                      persistent-hint
                      required 
                    />
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="3" >
                    <v-autocomplete 
                      dense  
                      label="Forma de pago" 
                      :items="formas_pago" 
                      v-model="forma_pago"  
                      item-text="nombre" 
                      item-value="codigo_sat" 
                      persistent-hint
                      required  
                    />
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
                    <!-- <v-autocomplete 
                    dense 
                    label="Vendedor" 
                    :items="vendedores" 
                    v-model="vendedor_id"  
                    item-text="nombre" 
                    item-value="id" 
                    persistent-hint
                    /> -->
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="2" >
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="2" >
                    <!-- <v-text-field 
                      dense 
                      text
                      prefix="$" 
                      locale="en-US" 
                      reverse
                      label="Tipo de cambio"
                      v-model="tipo_cambio">
                    </v-text-field> -->
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="6" lg="6" xl="4">
                    <!-- <v-select 
                      dense 
                      clearable 
                      v-model="registroActual.razon_social_id"   
                      label="RazÃƒÂ³n social" 
                      :items="razones_sociales" 
                      item-text="razon_social" 
                      item-value="id"
                      @change="UpdateRazonSocial()"
                    >
                    </v-select> -->
                  </v-col>
                  <v-col cols="12" xs="12" sm="3" md="3" lg="3" xl="2" >
                    <!-- <v-menu ref="menu1" v-model="dpFechaVencimiento" 
                      :nudge-right="40"
                      transition="scale-transition"
                      offset-y
                      min-width="auto">
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field 
                        dense 
                        clearable 
                        :disabled="registroActual.metodo_pago == 'PUE'"
                        :rules="RULES.fecha"
                        v-model="registroActual.fecha_vencimiento"  
                        label="Fecha de vencimiento" 
                        persistent-hint
                        v-bind="attrs" v-on="on"></v-text-field>
                        </template>
                      <v-date-picker v-model="registroActual.fecha_vencimiento" no-title @input="dpFechaVencimiento = false"></v-date-picker>
                    </v-menu> -->
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
                    <!-- <v-autocomplete  
                            dense  
                            v-model="registroActual.uso_cfdi" 
                            label="Uso de CFDI" 
                            :items="usos_cfdi" 
                            item-text="descripcion" 
                            item-value="id"
                          />   -->
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" xs="12" sm="6" md="6" lg="6" xl="4">
                    <v-textarea 
                      label="Notas" 
                      rows="3"
                      dense
                      outlined
                      v-model="registroActual.notas" 
                    ></v-textarea>
                  </v-col>
                  <v-col cols="6" xs="6" sm="1" md="1" lg="1" xl="1" >
                    <div class="text-h6 mb-1 font-weight-bold text-right ">
                        Total:
                    </div>
                  </v-col>
                  <v-col cols="6" xs="6" sm="2" md="2" lg="2" xl="1" >
                    <div class="text-h6 mb-1 font-weight-bold text-right ">
                        {{FORMAT_CURRENCY(parseFloat(total))}}
                    </div>
                  </v-col>
                </v-row>
                 
              </v-form>
            </v-container>
            <h-grid
              :items="items"
              @update="onUpdateDetalle"
              @add="onAddDetalle"
            />
          </v-card-text>
        </v-card>
    </v-dialog>
</template>
<script>
import axios from 'axios'; 
import Vue from 'vue'
export default {
    props:[
      'id',
      'show',
      'isNew',
      'export_contacto_id'
    ],
    data: () => ({
      registroActual: {},
      sucursales: [
        { id: 1, nombre: 'Sabinas Hidalgo' },
      ],
      sucursal_id: 1,
      series: [],
      serie: 'GAS',
      clientes: [],
      contactoSeleccionado: [],
      contactos: [],
      contacto_id: null,
      items:[],
      vendedores: [],
      vendedor_id: 0,
      almacenes: [],
      almacen_id: 0,
      contact_id: 0,
      dpFecha: '',
      razones_sociales: [],
      metodos_pago: [
        {id: 'PUE', descripcion: 'Pago de Una Sola Exhibición' },
        {id: 'PPD', descripcion: 'Pago en Parcialidades o Diferido' },
      ],
      metodo_pago: 'PUE',
      formas_pago: [
        { codigo_sat: '01', nombre: 'Efectivo' },
        { codigo_sat: '02', nombre: 'Cheque nominativo' },
        { codigo_sat: '03', nombre: 'Transferencia electrónica de fondos' },
        { codigo_sat: '04', nombre: 'Tarjeta de crédito' },
        { codigo_sat: '05', nombre: 'Monedero electrónico' },
        { codigo_sat: '06', nombre: 'Dinero electrónico' },
        { codigo_sat: '08', nombre: 'Vales de despensa' },
        { codigo_sat: '12', nombre: 'Dación en pago' },
        { codigo_sat: '13', nombre: 'Pago por subrogación' },
        { codigo_sat: '14', nombre: 'Pago por consignación' },
        { codigo_sat: '15', nombre: 'Condonación' },
        { codigo_sat: '17', nombre: 'Compensación' },
        { codigo_sat: '23', nombre: 'Novación' },
        { codigo_sat: '24', nombre: 'Confusión' },
        { codigo_sat: '25', nombre: 'Remisión de deuda' },
        { codigo_sat: '26', nombre: 'Prescripción o caducidad' },
        { codigo_sat: '27', nombre: 'A satisfacción del acreedor' },
        { codigo_sat: '28', nombre: 'Tarjeta de débito' },
        { codigo_sat: '29', nombre: 'Tarjeta de servicios' },
        { codigo_sat: '30', nombre: 'Aplicación de anticipos' },
        { codigo_sat: '99', nombre: 'Por definir' },
      ],
      forma_pago: '99',
      dpFechaVencimiento: '',
      usos_cfdi: [],
      moneda_id: 0,
      tipo_cambio: 1,
      fecha: '',
      total: 0,

    }),
    mounted() {
      this.getSeries();
    },
    methods: {
      getRegistro(){

      },
      Guardar(){
        this.registroActual.metodo_pago = this.metodo_pago;
        this.registroActual.forma_pago = this.forma_pago;
        this.registroActual.tipo_cambio =  this.tipo_cambio;
        this.registroActual.serie =  this.serie;
        this.registroActual.total = this.total;
        this.registroActual.contacto_id = this.contacto_id;
        this.registroActual.items = this.items;
        if(this.isNew){
          axios
          .post(this.URL('/ventas'),this.registroActual)
          .then(response => {
            this.$emit('new',response.data);
          })
          .catch(this.MSGBOX);
        }else{
          console.log('Editar Registro');
        }
      },
      Close(){
        this.$emit('close');
      },
      onAddDetalle(val){
        console.log(val);
        this.items.push(val);
      },
      onUpdateDetalle(value) {
        //forzamos la asignacion de un nuevo array en cada cambio
        this.items = value.map(i => i);
      },
      getContactos(){
        console.log('Obtener contactos');
        axios
        .get(this.URL('/contactos'))
        .then(response => {
          this.contactos = response.data;
          if(this.export_contacto_id){
            this.contacto_id = this.export_contacto_id;
            console.log('this.contacto_id: '+this.contacto_id);
          }
        })
        .catch(this.MSGBOX);
      },
      getSeries() { 
        axios
        .get(this.URL('/series_cfdi'))
        .then(response => {
          this.series = response.data;
        })
        .catch(this.MSGBOX);
      },
    },
    watch: {
      show() {
        if(!this.show) return;
        this.getContactos();
        console.log('venta this.isNew:'+this.isNew);
        if(this.isNew){
          this.registroActual = {};
          this.registroActual.fecha = this.getDateTime();
          return;
        }
        this.getRegistro();
      
      },
      items(val) {
        console.log(val);
        let t = 0;
        this.items.forEach(e => {
          t =  t + parseFloat(e.precio);
        });
        this.total = parseFloat(t);
      }
  }
};
</script>